@use 'src/theme/helpers' as *;
@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/color.module' as *;
@use 'src/theme/spacings.module' as *;

.root {
  --radius: var(--cor_rad-lg);

  &.maskTop {
    --mask-origin: top right;
    --skew-content: skew(0, calc(var(--brand-angle) * -1));
    --skew-image: skew(0, var(--brand-angle));
  }
  &.maskLeft {
    --mask-origin: bottom left;
    --skew-content: skew(calc(var(--brand-angle) * -1));
    --skew-image: skew(var(--brand-angle));
  }
}

.root,
.content {
  position: relative;
  border-radius: var(--radius);
  overflow: hidden;
  width: 100%;
}

.content,
.image {
  transform-origin: var(--mask-origin);
}

.content {
  transform: var(--skew-content);
  height: 100%;
}

.image {
  transform: var(--skew-image);
  display: block;
  position: relative;
  object-fit: cover;
  width: 100%;
  height: 100%;
  background: var(--whi-200);
}
